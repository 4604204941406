// Supermove
import {gql} from '@supermove/graphql';
import {LeadStageModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

const getActiveLeadStages = withFragment(
  (leadStages: LeadStageModel[]) => {
    return leadStages.filter((stage) => !stage.archivedAt);
  },
  gql`
    fragment LeadStage_getActiveLeadStages on LeadStage {
      id
      archivedAt
    }
  `,
);

const getArchivedLeadStages = withFragment(
  (leadStages: LeadStageModel[]) => {
    return leadStages.filter((stage) => !!stage.archivedAt);
  },
  gql`
    fragment LeadStage_getArchivedLeadStages on LeadStage {
      id
      archivedAt
    }
  `,
);

const COLOR_SELECTOR_OPTIONS = {
  Purple: '#9B51E0',
  Blue: '#34ACE0',
  Green: '#27AE60',
  Yellow: '#F1C40F',
  Orange: '#F2A12C',
  Red: '#E74C3C',
  Pink: '#E84393',
  Grey: '#616161',
  Black: '#000000',
};

const getColorSelectorDropdownOptions = () =>
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  Object.keys(COLOR_OPTIONS).map((key, index) => ({label: key, value: COLOR_OPTIONS[key]}));

const LeadStage = {
  getActiveLeadStages,
  getArchivedLeadStages,
  getColorSelectorDropdownOptions,
};

export default LeadStage;
